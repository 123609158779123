import request from "@/utils/request";

/**
 * @return Promise
 */
export function getDailyCloseStoreList(bsnDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/daily_close_management/front-daily-close/store-list",
    method: "get",
    params: {
      bsnDate: bsnDate,
    },
  });
}

/**
 * @return Promise
 */
export function getDailyCloseCheckList() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/daily_close_management/front-daily-close/check-list",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function getDailyCloseCancelHistory(closeId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/daily_close_management/front-daily-close/close-cancel-history",
    method: "get",
    params: {
      closeId: closeId,
    },
  });
}

/**
 * @return Promise
 */
export function getDailyCloseMonthlyStatus(bsnDate, storeCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/daily_close_management/front-daily-close/monthly-close-status",
    method: "get",
    params: {
      bsnDate: bsnDate,
      storeCode: storeCode,
    },
  });
}

/**
 * @return Promise
 */
export function getDailyCloseUnsettledList(bsnDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/daily_close_management/front-daily-close/unsettled-list",
    method: "get",
    params: {
      bsnDate: bsnDate,
    },
  });
}

/**
 * @return Promise
 */
export function getDailyCloseSms(bsnDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/daily_close_management/front-daily-close/${bsnDate}/sms`,
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putDailyCloseExecute(bsnDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/daily_close_management/front-daily-close/execute/${bsnDate}`,
    method: "put",
  });
}

/**
 * @return Promise
 */
export function putDailyCloseCancel(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/daily_close_management/front-daily-close/cancel",
    method: "put",
    data,
  });
}
