<template>
  <div id="popupWrapper">
    <ejs-dialog
        ref="dailyCloseSendSmsPopup"
        :header="`마감 SMS 발송 (${bsnDate})`"
        allowDragging="true"
        showCloseIcon="true"
        isModal="true"
        width="320"
        height="850"
        :enableResize="true"
        :animationSettings="{ effect: 'None' }"
        :visible="isVisible"
        @close="onDailyCloseSendSmsPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section
                  class="article-section section-01"
                  style="height: calc(100% - 67px)"
                >
                  <div class="sms-send-box">
                    <div class="preview-top">
                      <div class="speaker-box"/>
                      <div class="speaker-small-box"/>
                    </div>
                    <div class="preview-middle">
                      <div class="screen-box">
                        <div class="user-profile">
                          <div class="image">{{ username.substr(0, 1) }}</div>
                          <div class="name">{{ username }}</div>
                        </div>
                        <div class="message-box">
                          [Web발신]
                          <br/>
                          <textarea
                              ref="aditWords"
                              placeholder="메시지를 입력해주세요"
                              v-model="aditWords"
                              @keyup="resize"
                              @keydown="resize"
                              @focus="resize"
                              spellcheck="false"
                              autofocus
                          />
                          <div
                              class="preview"
                              v-if="
                              smsSendInfo.bizNameLocation === 'DOWN' &&
                              smsSendInfo.bizName
                            "
                          >
                            <br/>
                            {{ smsSendInfo.bizName }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="preview-bottom">
                      <div class="button-box"/>
                    </div>
                  </div>
                </section>
                <section
                  class="article-section section-02"
                  style="height: 67px"
                >
                  <div class="section-body" style="border: none; overflow: hidden !important;">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title">발신번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="dsptchNo"
                                          v-model="smsSendInfo.dsptchNo"
                                          disabled
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title">파일첨부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input" style="width: calc(100% - 42px);">
                                    <div class="form">
                                      <input-text
                                        ref="file"
                                        v-model="fileInfo.name"
                                        disabled="false"
                                      />
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group button"
                                    style="padding: 1px 1px 0 1px;"
                                  >
                                    <ul
                                      class="button"
                                      style="list-style: none; padding: 0; margin: 0; display: flow-root;"
                                    >
                                      <li
                                        class="registration"
                                        style="float: left; margin: 0px;"
                                      >
                                        <div
                                          class="e-upload e-control-wrapper e-lib e-keyboard"
                                          style="position: relative; border: none; font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif', '-apple-system', 'BlinkMacSystemFont';"
                                        >
                                          <div
                                            class="e-file-select-wrap file"
                                            style="padding: 0;"
                                          >
                                            <label
                                              class="e-css e-btn"
                                              for="closeFileUploader"
                                              style="
                                                box-shadow: 0 3px 3px 0 rgb(0 0 0 / 3%);
                                                color: #666; border-color: #e0e0e0;
                                                border-color: rgb(224, 224, 224);
                                                font-weight: normal;
                                                font-family: '돋움', Dotum, Arial, Verdana, sans-serif;
                                                padding: 4px 7px 2px 7px;
                                                background-image: none;
                                                background-repeat: no-repeat;
                                                background-position: left -84px;
                                                border-radius: 3px;
                                                font-size: 12px;
                                              "
                                            >
                                              찾기
                                            </label>
                                            <span class="e-file-select" style="display: inline-block; width: 0; position: absolute; top: 0; left: 0;">
                                              <input
                                                id="closeFileUploader"
                                                name="closeFileUploader"
                                                ref="closeFileUploader"
                                                class="e-control e-uploader e-lib"
                                                type="file"
                                                aria-label="Uploader"
                                                style="width: 0; display: inline-block; opacity: 0;"
                                                maxlength="10000000"
                                                hidden
                                                @change="onCloseFileUploaderChange"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :ignore="showTestSmsSendPopup"
                  :is-custom-shortcut-button="true"
                  shortcut-key="DailyCloseSendSmsPopup.shortcuts.sendSMS"
                  :shortcut="{key: 'F7'}"
                  @click.native="onSmsSendButtonClicked()"
              >
                전송
              </erp-button>
            </li>
            <li>
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onTestButtonClicked">
                검증
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeDailyCloseSendSmsPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <test-sms-send-popup
        v-if="showTestSmsSendPopup"
        ref="testSmsSendPopup"
        @smsSend="smsSend"
        @popupClosed="onTestSmsSendPopup"
    />
  </div>
</template>

<style scoped>
.item.form-group.button .e-file-select-wrap.file .e-css.e-btn {background-color: #fff;}
.item.form-group.button .e-file-select-wrap.file .e-css.e-btn:hover {background-color: #f0f0f0;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import {getDailyCloseSms} from '@/api/frontDailyClose';
import {commonCodesGetStandardInfo} from '@/utils/commonCodes';
import {getDayOfWeekCaption, getDayOfWeekCaptionColor} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {getFormattedTelNumber} from '@/utils/string';
import {validateFormRefs} from '@/utils/formUtil';
import moment from 'moment';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import InputText from '@/components/common/text/InputText';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {mapGetters} from 'vuex';
import TestSmsSendPopup from '@/components/popups/TestSmsSendPopup';
import {getSmsLastInfo} from '@/api/systemConfig';
import {encodeBase64} from "@/utils/ImageUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'DailyCloseSendSmsPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {TestSmsSendPopup, InputText, ErpButton},
  data() {
    return {
      isSmsSend: false,
      isVisible: false,
      showTestSmsSendPopup: false,
      bsnDate: null,
      closeDiv: null,
      dailyCloseInfo: {},
      smsSendInfo: {},
      smsEndPersonList: [],
      aditWords: '',
      fileInfo: {
        path: null,
        name: null,
        imageId: null,
        uploadDiv: "CLOSE",
        url: null,
      }
    };
  },
  async beforeDestroy() {
    if (!this.isSmsSend) {
      if (this.fileInfo.imageId) {
        await GolfErpAPI.deleteImage(this.fileInfo.imageId);
      }
    }
  },
  computed: {
    ...mapGetters(['username']),
  },
  methods: {
    numberWithCommas,
    validateFormRefs,
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    async showPopup(bsnDate, closeDiv) {
      this.bsnDate = bsnDate;
      this.closeDiv = closeDiv;
      await this.fetchSmsWords();
      await this.$nextTick();
      await this.resize();
    },
    onDailyCloseSendSmsPopupClosed() {
      this.$emit('popupClosed');
    },
    closeDailyCloseSendSmsPopup() {
      this.isVisible = false;
      this.$refs.dailyCloseSendSmsPopup.hide();
    },
    async fetchSmsWords() {
      this.smsWords = await GolfErpAPI.fetchSmsWords();
      const smsSendType = this.smsWords.find(
          (data) => data.smsSendType === 'CLOSE_GOLF',
      );
      if (!smsSendType) {
        this.errorToast('일치하는 SMS전송타입이 존재하지 않습니다');
        this.closeDailyCloseSendSmsPopup();
        return;
      }
      try {
        const {status, value} = await getDailyCloseSms(this.bsnDate);
        if (!status) {
          this.errorToast('API 정보가 비정상입니다');
          return;
        }
        this.dailyCloseInfo = value;
      } catch (e) {
        this.closeDailyCloseSendSmsPopup();
        throw e;
      }
      const todayTitle = [
        `[${moment(this.bsnDate).format('MM월 DD일')} 영업보고_어등산CC]`,
      ];
      const todayVisitContent = [
        `내장 ${this.dailyCloseInfo.todayVisitTeamCount}팀 / 인원 ${this.dailyCloseInfo.todayVisitorCount}명`
      ];
      const todaySalesContent = [
        `총매출 : ${numberWithCommas(this.dailyCloseInfo.todayTotSalesAmt)}원`,
        `세   금 : ${numberWithCommas(this.dailyCloseInfo.todayTotTaxAmt)}원`,
        `순매출 : ${numberWithCommas(this.dailyCloseInfo.todayTotNetSalesAmt)}원`,
        `(입장매출 : ${numberWithCommas(this.dailyCloseInfo.todayFeeSalesAmt)}원`,
        ` 식음매출 : ${numberWithCommas(this.dailyCloseInfo.todayFoodSalesAmt)}원`,
        ` 카트매출 : ${numberWithCommas(this.dailyCloseInfo.todayCartSalesAmt + this.dailyCloseInfo.todayRentSalesAmt)}원`,
        ` 프로샵매출 : ${numberWithCommas(this.dailyCloseInfo.todayShopSalesAmt)}원 포함)`,
        ` 객단가 : ${numberWithCommas(this.dailyCloseInfo.todayPersonAmt)}원`,
        "",
        `연매출누계 : ${numberWithCommas(this.dailyCloseInfo.yearTotNetSalesAmt)}원`,
      ];
      // const todayPayContent = [
      //   // `◆ 수입현황`,
      //   // `  현금 : ${numberWithCommas(this.dailyCloseInfo.todayPayCashAmt)}원`,
      //   // `  카드 : ${numberWithCommas(this.dailyCloseInfo.todayPayCardAmt)}원`,
      //   // `  선불 및 상품권 : ${numberWithCommas(this.dailyCloseInfo.todayPayDepositGiftAmt)}원`,
      //   // `  미수 : ${numberWithCommas(this.dailyCloseInfo.todayPayCreditAmt)}원`,
      //   // `  계좌이체 : ${numberWithCommas(this.dailyCloseInfo.todayPayTransAmt)}원`
      // ];
      // const todayCreditReturnContent = [
      //   // `◆ 미수회입`,
      //   // `  회입금액 : ${numberWithCommas(this.dailyCloseInfo.todayCreditReturnAmt)}원`
      // ];

      const todayContent = [
        todayTitle.join("\n"),
        todayVisitContent.join("\n"),
        todaySalesContent.join("\n"),
        // todayPayContent.join("\n"),
        // todayCreditReturnContent.join("\n"),
      ];

      const todaySms = todayContent.join("\n");

      const tomorrowTitle = [
        `[팀수보고]`
      ];
      const tomorrowInfoContent = [
        `${moment(this.bsnDate).add(1, "days").format('MM/DD(ddd)')} : ${this.dailyCloseInfo.isTomorrowClosed ? "휴장" : this.dailyCloseInfo.tomorrowResveTeamCount + "팀"}`,
        `${moment(this.bsnDate).add(2, "days").format('MM/DD(ddd)')} : ${this.dailyCloseInfo.isDay2Closed ? "휴장" : this.dailyCloseInfo.day2ResveTeamCount + "팀"}`,
        `${moment(this.bsnDate).add(3, "days").format('MM/DD(ddd)')} : ${this.dailyCloseInfo.isDay3Closed ? "휴장" : this.dailyCloseInfo.day3ResveTeamCount + "팀"}`,
        // this.dailyCloseInfo.isTomorrowClosed ?
        //   `  ${moment(this.bsnDate).add(1, 'days').format('MM/DD(ddd)')} 휴장입니다.` :
        //   `  ${moment(this.bsnDate).add(1, 'days').format('MM/DD(ddd)')}`,
        // `  예약팀수 : ${numberWithCommas(this.dailyCloseInfo.tomorrowResveTeamCount)}팀`,
        // `  첫팀시간 : ${this.dailyCloseInfo.tomorrowFirstTeamTime ? this.dailyCloseInfo.tomorrowFirstTeamTime : ''}`,
        // `  막팀시간 : ${this.dailyCloseInfo.tomorrowLastTeamTime ? this.dailyCloseInfo.tomorrowLastTeamTime : ''}`,
      ];

      const tomorrowContent = [
        tomorrowTitle.join("\n"),
        tomorrowInfoContent.join("\n"),
      ];

      const tomorrowSms = tomorrowContent.join("\n");

      // const bigoContent = [
      //   // "◆ 특이사항",
      // ];

      let resultSms = [
        todaySms,
        tomorrowSms,
        // bigoContent
      ];
      resultSms = resultSms.join('\n\n');
      const {
        value: {tsConfSmsCloseList},
      } = await getSmsLastInfo(true);
      this.smsEndPersonList = tsConfSmsCloseList;
      this.aditWords = [resultSms, smsSendType.aditWords].join('\n');
      this.smsSendInfo.message = "";
      this.smsSendInfo.type = smsSendType.smsSendType;
      this.smsSendInfo.kakaoDispatchKey = commonCodesGetStandardInfo("kakaoDsptchKey");
      this.smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      this.smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      this.smsSendInfo.smsKind = smsSendType.smsKind;
      this.smsSendInfo.subject = smsSendType.smsSendTypeName;
      this.smsSendInfo.dsptchNo = getFormattedTelNumber(
          smsSendType.dsptchNo
              ? smsSendType.dsptchNo
              : commonCodesGetStandardInfo('dsptchNo'),
      );
      this.smsSendInfo.file = null;
      this.isVisible = true;
    },
    async onSmsSendButtonClicked() {
      if (!(await this.confirm('SMS를 전송하시겠습니까?'))) {
        return;
      }

      await this.smsSend();
    },
    onTestButtonClicked() {
      this.showTestSmsSendPopup = true;
      this.$nextTick(() => {
        this.$refs.testSmsSendPopup.showPopup();
      });
    },
    async smsSend(contactTel = null) {
      let message = [];
      if (this.smsSendInfo.message) {
        message.push(this.smsSendInfo.message);
      }
      message.push(this.aditWords);
      if (this.bizNameFlag && this.smsSendInfo.bizName) {
        this.smsSendInfo.bizNameLocation === 'UP'
            ? message.unshift(this.smsSendInfo.bizName)
            : message.push(this.smsSendInfo.bizName);
      }
      message = message.join('\n\n');

      let smsRequestList = [];
      if (contactTel) {
        smsRequestList.push(this.makeSmsInfoTemplate(contactTel, message));
      } else {
        this.smsEndPersonList.forEach(item => {
          if (item.closeSendFlag) {
            smsRequestList.push(this.makeSmsInfoTemplate(item.hpNo, message, item.sendTrget));
          }
        });
      }

      this.showTestSmsSendPopup = false;

      await GolfErpAPI.sendSMS(smsRequestList);
      this.infoToast(this.$t('sms.popupMessage.success'));
      this.isSmsSend = true;
    },
    makeSmsInfoTemplate(contactTel, message, receiverName = '검증') {
      return {
        type: this.smsSendInfo.type,
        smsKind: this.smsSendInfo.kakaoSendFlag
          ? this.smsSendInfo.smsKind
          : null,
        key: 0,
        kakaoFlag: this.smsSendInfo.kakaoSendFlag,
        kakaoDispatchKey: this.smsSendInfo.kakaoSendFlag
          ? this.smsSendInfo.kakaoDispatchKey
          : null,
        kakaoTemplateKey: this.smsSendInfo.kakaoSendFlag
          ? this.smsSendInfo.kakaoTemplateCode
          : null,
        receiverName,
        receiverNumber: contactTel.replace(/-/gi, ''),
        message,
        dispatchNumber: this.smsSendInfo.dsptchNo.replace(/-/gi, ''),
        sender: 'ERP',
        subject: this.smsSendInfo.subject
      };
    },
    onTestSmsSendPopup() {
      this.showTestSmsSendPopup = false;
    },
    async resize() {
      if (this.$refs.aditWords) {
        const container = document.querySelector(".screen-box");
        const saved = container.scrollTop;
        this.$refs.aditWords.style.height = 0;
        this.$refs.aditWords.style.height = `${this.$refs.aditWords?.scrollHeight}px`;
        await this.$nextTick();
        container.scrollTop = saved;
      }
    },
    async onCloseFileUploaderChange(args) {
      try {
        let aditWordsIndexOf = -1;
        if (this.fileInfo.imageId) {
          await GolfErpAPI.deleteImage(this.fileInfo.imageId);

          aditWordsIndexOf = this.aditWords.indexOf("https://");
          if (aditWordsIndexOf >= 0) {
            this.aditWords = this.aditWords.slice(0, aditWordsIndexOf);
          }
          this.fileInfoInit();
        }

        if (args.target.files.length > 0) {
          // 파일을 선택한 경우
          const file = args.target.files[0];

          // 파일사이즈(소수 2번째 자리까지 구함)
          const fileSize = Math.trunc(file.size * 100 / 1024 / 1024) / 100;

          if (fileSize > 10) {
            this.errorToast("파일은 10MB 미만만 첨부 가능합니다");
            return;
          }

          this.fileInfo.path = await encodeBase64(file);
          this.fileInfo.name = file.name;

          const fileUploadData = {
            imageFile: {
              path: this.fileInfo.path,
              name: this.fileInfo.name,
            },
            uploadDiv: this.fileInfo.uploadDiv,
            imageId: this.fileInfo.imageId,
          };

          const rtnData = await GolfErpAPI.putImageUpload(fileUploadData);

          this.fileInfo.imageId = rtnData.imageId;
          this.fileInfo.url = rtnData.imageURL;

          this.aditWords += (aditWordsIndexOf >= 0 ? "" : "\n") + this.fileInfo.url;
        } else {
          // 파일을 선택 안하고 취소 버튼을 누른 경우
          this.fileInfoInit();
        }
      } catch (e) {
        console.error(e);
        this.errorToast("파일 첨부 중 오류가 발생하였습니다");
        this.fileInfoInit();
      }
    },
    fileInfoInit() {
      this.fileInfo.path = null;
      this.fileInfo.name = null;
      this.fileInfo.imageId = null;
      this.fileInfo.url = null;
    }
  },
};
</script>
